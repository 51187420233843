table.subjectsTable
{
   border: 2px solid black;
   border-spacing: 0;
   margin-bottom: 1rem;
}

table.subjectsTable tr
{
   background-color: #fff;
}

table.subjectsTable td
{
    padding: 0.4rem 1rem;
    hyphens: auto;
    font-size: 1.2rem;
    border-top: 1px solid lightgray;
}

table.subjectsTable th
{
    padding: 1.2rem 1rem 0.4rem;
    hyphens: auto;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    text-align: left;
}

table.subjectsTable tr.level_1
{
   background-color: bisque;
}

table.subjectsTable tr.level_1:hover,
table.subjectsTable tr.level_2:hover
{
   background-color: #fff;
   cursor: pointer;
}

table.subjectsTable tr.level_1:active,
table.subjectsTable tr.level_2:active
{
   background-color: #eee;
}

table.subjectsTable tr.level_2
{
   background-color: lightblue;
}


.t-Button
{
 /*display: none;*/
}

div.quota
{
   padding: 0.4rem;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
    hyphens: auto;
   font-size: 1.2rem;
   border: 1px solid black;
}

.quotaDadesBancaries
{
   margin: 2rem 0;
}

.quotaTitol,
.quotaNomCamp
{
   line-height: 1.6rem;
    font-weight: bolder;
}

.dropZone
{
   display: inline-block;
   width: 100%;
   border: 1px solid #dfdfdf;
   border-radius: 2px;
   background-color: #f9f9f9;
   color: #393939;
   font-size: 1.2rem;
   padding: 0.3rem;
   line-height: 1.6rem;
   vertical-align: top;
   transition: background-color 0.1s ease, border 0.1s ease;
}

table.quota
{
   padding: 0.4rem;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
    hyphens: auto;
   font-size: 1.2rem;
   min-width: 50%;
}

table.quota th
{
   text-align: left;
   font-size: 1.2rem;
   padding: 0.4rem;
   margin: 0.6rem 0;
}

table.quota td
{
   padding: 0.4rem 1rem;
   border-top: 1px solid  lightgray
}

table.quota tr td:nth-child(2)
{
   text-align: right;
}

table.quota td.quotaTotal
{
   font-size: 1.4rem;
   font-weight: 800;
}

p {
   font-family: 'OpenSansRegular', Helvetica, Arial, sans-serif;
   font-size: 1.2rem;
   padding: 0.4rem 0;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
   display: inline-block;
   line-height: 1.2rem;
   text-align: justify;
}